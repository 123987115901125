export default {
    SingleLineText: 0,
    MultiLineText: 1,
    Header: 2,
    ReadOnlyText: 3,
    SingleSelectList: 4,
    SingleSelectOptions: 5,
    MultiSelect: 6,
    PrePopulated: 7,
    Number: 8,
    Boolean: 9,
    DateOnly: 10,
    TimeOnly: 11,
    DrawPad: 12,
    VehicleCanvas: 13,
    FileUpload: 14,
    PersonCanvas: 15
}