import * as Utils from './utils.js'
import autosize from 'autosize'

export function registerConfigurationSavedEvent() {
    let element = Utils.querySelector(this, '.cf-config-save');

    if (element) {
        element.addEventListener('click', event => this.saveConfiguration(event));
    }
}

export function registerOptionAddedEvent() {
    let element = Utils.querySelector(this, '.cf-config-add-option');

    if (element) {
        element.addEventListener('click', event => this.addNewOption(event));
    }
}

export function registerOptionDeletedEvent() {
    Utils.querySelectorAll(this, '.cf-config-delete-option')
        .forEach(element => element.addEventListener('click', event => this.deleteOption(event)));
}

export function registerConditionAddedEvent() {
    let element = Utils.querySelector(this, '.cf-config-add-condition');

    if (element) {
        element.addEventListener('click', event => this.addNewCondition(event));
    }
}

export function registerConditionDeletedEvent() {
    Utils.querySelectorAll(this, '.cf-config-delete-condition')
        .forEach(element => element.addEventListener('click', event => this.deleteCondition(event)));
}

export function registerFormInputChangedEvent() {
    let element = Utils.querySelector(this, '.cf-form');

    if (element) {
        element.addEventListener('cfEvent', event => this.handleFormInputChange(event));
    }
}

export function registerSectionControlEvent() {    
    Utils.querySelectorAll(this, '.cf-section-control')
        .forEach(element => element.addEventListener('click', event => this.handleSectionControlEvent(event)));
}

export function registerComponentControlEvent() {
    Utils.querySelectorAll(this, '.cf-component-control')
        .forEach(element => element.addEventListener('click', event => this.handleComponentControlEvent(event)));
}

export function registerBorderDropdownButtonControlEvent() {
    let borderControl = Utils.getElementById('border-dropdown-button');
    if (borderControl)
    {
        borderControl.addEventListener('click', event => this.showBorderDropdownOptions(event));
    }
}

export function registerFormBuilderControlEvent() {
    if (document.getElementById('form-builder')) {
        Utils.getElementById('form-builder').addEventListener('click', event => this.hideBorderDropdownOptions(event));
    } else if (document.getElementById('form-builder-container')) {
        Utils.getElementById('form-builder-container').addEventListener('click', event => this.hideBorderDropdownOptions(event));
    }
}

export function registerBorderDropdownControlEvent() {

    let printAllElementsBorderControl = Utils.getElementById('print-all-elements-border-pdf');
    if (printAllElementsBorderControl)
    {
        printAllElementsBorderControl.addEventListener('click', event => this.printAllElementsWithBorder(event));
    }

    let printAllSectionsBorderControl = Utils.getElementById('print-all-sections-border-pdf');
    if (printAllSectionsBorderControl)
    {
        printAllSectionsBorderControl.addEventListener('click', event => this.printAllSectionsWithBorder(event));
    }
}

export function handleShowConfirmDeleteModal() {
    Utils.querySelectorAll(this, '.cf-section-control')
        .forEach(element => element.addEventListener('click', event => this.handleSectionControlEvent(event)));
}

export function registerComponentTemplatesGroupSelectedEvent() {
    let nextElement = Utils.querySelector(this, '.cf-component-template-group-next');

    if (nextElement) {
        nextElement.addEventListener('click', event => this.selectNextGroupTemplates(event));
    }

    let previousElement = Utils.querySelector(this, '.cf-component-template-group-previous');

    if (previousElement) {
        previousElement.addEventListener('click', event => this.selectPreviousGroupTemplates(event));
    }
}

export function registerComponentOptionKeyDownEvent() {
    let optionValueElement = Utils.querySelector(this, '.cf-component-option-value');

    if(optionValueElement){
        optionValueElement.addEventListener('keydown', event => this.optionValueKeyDown(event));
    }
}

// Note that this is a workaround because change event does not fire for ionic checkbox/radio/dropdown for some reason
export function registerForIonicEvents() {
    Utils.querySelectorAll(this, 'ion-radio')
        .forEach(ionComponent => ionComponent.addEventListener('click', event => {
            Utils.handleChangeEvent(event.target);
        }));

    Utils.querySelectorAll(this, 'ion-select, ion-checkbox')
        .forEach(ionComponent => ionComponent.addEventListener('ionChange', event => {
            Utils.handleChangeEvent(event.target);
        }));
}