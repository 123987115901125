import '../styles/index.css'

import {
    Form,
    FormSection
} from './form.js';

import Condition from './conditions';

import {
    Component,
    ComponentTemplateGroups
} from './components.js';

import * as Utils from './utils.js';
import Templates from './templates.js'
import * as EventRegistrations from './eventRegistrations.js'
import FieldInputTypes from './fieldInputTypes.js'

import autosize from 'autosize'
import Sortable from 'sortablejs';
import 'globalthis/auto';

(function() {
    globalThis.FormBuilder = class FormBuilder {

        constructor() {
            this.id = Utils.uniqueId();
            this.form = new Form();
            this.currentSection = 0;
            this.sectionsRendered = [];
            this.sortables = [];
            this.defaultOptions = {
                rowLimit: 10,
                maxUploadFileSize: '10MB',
                fileUploadErrorCallback: (errorData) => console.log(errorData)
            };

            globalThis.activeCustomFormTemplate = document.querySelector('ion-app') != null ? 'mdata' : 'data';
        }

        render(mainContainer, formData = null, params, options, renderBySection = false, isEditMode = false) {
            this.params = params || {};
            this.options = Object.assign(this.defaultOptions, options || {});

            if (formData) {
                this.form = Form.parse(formData);
            }

            if (this.params.ticketNumber && this.form.header) {
                this.form.header.components[0].content = this.params.ticketNumber;
            }

            (this.params.componentsValueMap || [])
            .forEach(componentsValue => {
                let component = Utils.getComponent(this.form, componentsValue.componentId);
                component.currentValues = [];

                if (component.typeId === FieldInputTypes.PrePopulated) {
                    component.content = componentsValue.componentCurrentValue;
                    component.showAsContent = true;
                    if (component.name == 'submissiongps')
                    {
                        component.isSubmissionGps = true;
                        let componentCurrentValues = componentsValue.componentCurrentValue.split('|');
                        component.showAsContent = componentCurrentValues.length > 1 ? false : true;
                        component.content = componentCurrentValues.length > 1 ? componentCurrentValues : componentsValue.componentCurrentValue;
                    }
                } else if (component.typeId === FieldInputTypes.MultiSelect) {
                    let componentCurrentValues = componentsValue.componentCurrentValue.split(',');
                    component.currentValues.push(...componentCurrentValues);
                } else {
                    component.currentValues.push(componentsValue.componentCurrentValue);
                }
            });

            if (!isEditMode)
            {
                this.applyHideFieldOnPdfSetting();

                this.applyHideUnselectedOptionsSetting();
            }

            let formComponentTemplateGroup = ComponentTemplateGroups.find(x => x.groupKey == 'Form');
            if (this.params.formType == 'Vehicle' || this.params.formType == 'Asset')
            {
                formComponentTemplateGroup.componentTemplates = formComponentTemplateGroup.componentTemplates.filter(x => x.name != 'personcanvas')
            }

            this.componentTemplateGroups = ComponentTemplateGroups.filter(ct => ['Form', 'Job', this.params.formType].includes(ct.groupKey));

            mainContainer.innerHTML = Utils.render(Templates.MainTemplate[globalThis.activeCustomFormTemplate], {
                vm: {
                    id: this.id,
                    form: this.form,
                    componentTemplateGroups: this.componentTemplateGroups
                }
            });

            EventRegistrations.registerFormInputChangedEvent.apply(this);
            this.buildForm(renderBySection);

            EventRegistrations.registerFormBuilderControlEvent.apply(this);
            EventRegistrations.registerBorderDropdownButtonControlEvent.apply(this);
            EventRegistrations.registerBorderDropdownControlEvent.apply(this);
        }

        get data() {
            return this.form;
        }

        get html() {
            return document.getElementById(this.id)
                .querySelector('.cf-form')
                .outerHTML;
        }

        clearData() {
            Utils.getAllComponents(this.form)
                .forEach(component => component.currentValues = []);
        }

        get isInPreviewMode() {
            let element = Utils.querySelector(this, '.cf-component-templates');

            if (element) {
                return element.classList.contains('cf-hidden');
            }

            return false;
        }

        set disableSorting(disabled) {
            this.sortables.forEach(sortable => sortable.option('disabled', disabled));
        }

        applyHideFieldOnPdfSetting()
        {
            this.form.sections.forEach(s => {
                var components = s.components.filter(c => 
                {
                    if (c.hideFieldOnPdf && Utils.checkValueIfNotApplicable(c.currentValues))
                    {
                        return false;
                    }

                    return true;
                });
                    
                s.components = components;
            })

            this.form.sections = this.form.sections.filter(x => x.components.length > 0)
        }

        applyPrintableFormDataAttribute()
        {
            let inputs = Utils.getElementsByTagName('input');
            if (!inputs || inputs.length == 0)
            {
                return;
            }

            Object.keys(inputs).forEach(ctrl => {
                let input = inputs[ctrl];
                if (input.type == "checkbox" || input.type == 'radio')
                {
                    input.setAttribute('data-pdf-form-field', true);
                }
            })
        }



        applyHideUnselectedOptionsSetting()
        {
            this.form.sections.forEach(s => {
                s.components.forEach(c => 
                {
                    if (c.hideUnselectedOptionsOnPdf)
                    {
                        c.options = Utils.checkValueIfNotApplicable(c.currentValues) ? 
                            [] : c.options.filter(x => c.currentValues.indexOf(x.value) > -1);
                    }
                });
            })
        }

        openPreview(renderBySection) {
            this.buildForm(renderBySection);
            autosize(Utils.querySelectorAll(this, 'textarea'));

            let borderControl = document.getElementsByClassName('border-dropdown-button-main')[0];
            if (borderControl)
            {
                borderControl.classList.add('cf-hidden');
            }

            let element = Utils.querySelector(this, '.cf-component-templates');

            if (element) {
                element.classList.add('cf-hidden');
            }

            Utils.querySelectorAll(this, '.cf-component-controls')
                .forEach(element => element.remove());

            Utils.querySelectorAll(this, '.cf-section, .cf-form-header')
                .forEach(element => element.classList.add('cf-section-preview'));

            document.getElementById(this.id)
                .classList.add('cf-form-builder-preview');

            // Note that this is a workaround because change event does not fire for ionic checkbox/radio/dropdown for some reason
            EventRegistrations.registerForIonicEvents.apply(this);

            Utils.evaluateConditions(this.form);
            this.disableSorting = true;
            autosize.update(Utils.querySelectorAll(this, 'textarea'));

            this.applyPrintableFormDataAttribute();
        }

        closePreview() {
            this.clearData();
            this.buildForm();

            document.getElementsByClassName('border-dropdown-button-main')[0].classList.remove('cf-hidden');

            let element = Utils.querySelector(this, '.cf-component-templates');

            if (element) {
                element.classList.remove('cf-hidden');
            }

            Utils.querySelectorAll(this, '.cf-section, .cf-form-header')
                .forEach(element => element.classList.remove('cf-section-preview'));

            document.getElementById(this.id)
                .classList.remove('cf-form-builder-preview');

            this.disableSorting = false;
        }


        buildForm(renderBySection = false) {
            this.currentSection = 0;
            this.sectionsRendered = [];
            let formElement = Utils.querySelector(this, '.cf-form');
            let template = Templates.FormTemplate[globalThis.activeCustomFormTemplate];

            if (renderBySection) {
                template = Templates.FormTemplateLazyLoading[globalThis.activeCustomFormTemplate];
            }

            if (formElement) {
                formElement.classList.remove('cf-hidden');

                let borderControl = document.getElementsByClassName('border-dropdown-button-main')[0];
                if (borderControl)
                {
                    borderControl.classList.remove('cf-hidden')
                }

                formElement.innerHTML = Utils.render(template, {
                    vm: {
                        form: this.form
                    }
                });
            }

            if (renderBySection) {
                this.buildSection(0);
            }

            let configElement = Utils.querySelector(this, '.cf-config');

            if (configElement) {
                configElement.classList.add('cf-hidden');
            }

            this.setupDragDrop();

            EventRegistrations.registerComponentControlEvent.apply(this);
            EventRegistrations.registerSectionControlEvent.apply(this);
        }

        buildSection(section) {
            let currentSectionRenderer = Utils.getElementById(this.form.sections[this.currentSection].id);

            if (currentSectionRenderer) {
                currentSectionRenderer.style.display = "none";
            }

            if (this.sectionsRendered.includes(section)) {
                let hiddenSection = Utils.getElementById(this.form.sections[section].id);

                if (hiddenSection) {
                    hiddenSection.style.display = "block";
                }

            } else {

                let sectionElement = Utils.querySelector(this, '.cf-sections');
                sectionElement.insertAdjacentHTML('beforeend',Utils.render(Templates.SectionLazyLoading[globalThis.activeCustomFormTemplate], {
                    vm: {
                        section: this.form.sections[section]
                    }

                }));
                this.sectionsRendered.push(section);
            }
            this.currentSection = section;
            let progressBarElement = Utils.querySelector(this, '.cf-progress-bar');
            progressBarElement.setAttribute("value",(this.currentSection/(this.form.sections.length-1)));
            EventRegistrations.registerForIonicEvents.apply(this);
            Utils.evaluateConditions(this.form);
        }

        setupDragDrop() {
            let self = this;

            let formElement = Utils.querySelector(self, '.cf-form');

            if (formElement) {
                let formSortable = Sortable.create(formElement, {
                    group: {
                        put: true,
                        pull: false
                    },
                    sort: true,
                    onAdd(event) {
                        self.createComponent(event);
                    },
                    onSort(event) {
                        self.reorder();
                    }
                });

                this.sortables.push(formSortable);
            }

            Utils.querySelectorAll(self, '.cf-section')
                .forEach(section => {
                    let sectionSortable = Sortable.create(section, {
                        group: {
                            put: true,
                            pull: true
                        },
                        sort: true,
                        onAdd(event) {
                            self.createComponent(event);
                        },
                        onSort(event) {
                            self.reorder();
                        }
                    });

                    self.sortables.push(sectionSortable);
                });

            this.renderComponentGroupTemplate();
        }

        reorder() {     

            let reorderedForm = new Form();

            let printAllElementsWithBorderControl = Utils.getElementById('print-all-elements-border-pdf');
            reorderedForm.hasBorderInAllElementsPdf = printAllElementsWithBorderControl.classList.contains('all-elements-printed-with-border')
            
            let printAllSectionsWithBorderControl = Utils.getElementById('print-all-sections-border-pdf');
            reorderedForm.hasBorderInAllSectionsPdf = printAllSectionsWithBorderControl.classList.contains('all-elements-printed-with-border')

            let formElement = Utils.querySelector(this, '.cf-form');

            if (formElement) {
                Array.from(formElement.children)
                    .forEach(childElement => {
                        let section = new FormSection();
                        if (childElement.classList.contains('cf-section-border')) {
                            section.hasBorderInSectionPdf = true;
                        }
                        if (childElement.classList.contains('cf-section') && childElement.children.length > 0) {
                            Array.from(childElement.children)
                                .forEach(componentElement => {                                
                                    if(componentElement.id !== "") {                                        
                                        section.components.push(Utils.getComponent(this.form, componentElement.id));
                                    }
                                });
                            reorderedForm.sections.push(section);
                        } else if (childElement.classList.contains('cf-component')) {
                            section.components.push(Utils.getComponent(this.form, childElement.id));
                            reorderedForm.sections.push(section);
                        }
                    });

                this.form = reorderedForm;
            }

            this.buildForm();
        }

        createComponent(event) {
            if (event.from.classList.contains('cf-component-templates-elements')) {
                let componentTemplate = [...ComponentTemplateGroups.map(group => group.componentTemplates)].flat()
                    .find(t => t.name === event.item.getAttribute('data-cf-component-name'))

                let section = null;
                let sectionElement = event.target.closest('.cf-section');

                if (!sectionElement) {
                    section = new FormSection();
                    var newIndex = event.newIndex === 0 ? event.newIndex : event.newIndex - 1;
                    this.form.sections.splice(newIndex, 0, section);
                } else {
                    section = Utils.getSection(this.form, sectionElement.id);
                }

                if (section.components.length < this.defaultOptions.rowLimit)
                {
                    componentTemplate.copyTo(section, event.newIndex);
    
                    if (this.form.hasBorderInAllElementsPdf) {
                        section.components
                        .forEach((component) => {
                            component.printWithBorderOnPdf = true;
                        });
                    }

                    if (this.form.hasBorderInAllSectionsPdf) {
                        section.hasBorderInSectionPdf = true;
                    }

                    this.buildForm();
                }
            } else {
                this.reorder();
            }
        }

        showConfig() {
            let formElement = Utils.querySelector(this, '.cf-form');
            let configElement = Utils.querySelector(this, '.cf-config');
            formElement.classList.add('cf-hidden');
            configElement.classList.remove('cf-hidden');

            formElement.dispatchEvent(new Event('cfEditComponentEvent', {
                bubbles: true
            }));
        }

        addNewOption(event) {
            event.preventDefault();

            let component = this.getInMemoryConfiguration();
            component.options.push({
                key: Utils.uniqueId(),
                value: ''
            });

            this.editConfiguration(JSON.stringify(component));
        }

        addNewCondition(event) {
            event.preventDefault();

            let component = this.getInMemoryConfiguration();
            component.conditions.push(new Condition());
            this.editConfiguration(JSON.stringify(component));
        }

        deleteOption(event) {
            event.preventDefault();

            let component = this.getInMemoryConfiguration();
            let optionKey = event.target.getAttribute('data-component-option-key');
            component.options = component.options.filter(option => option.key !== optionKey);
            this.editConfiguration(JSON.stringify(component));
        }

        deleteCondition(event) {
            event.preventDefault();

            let component = this.getInMemoryConfiguration();
            let conditionIdToBeDeleted = event.target.getAttribute('data-component-condition-id');
            component.conditions = component.conditions.filter(condition => condition.id !== conditionIdToBeDeleted);
            this.editConfiguration(JSON.stringify(component));
        }

        renderConditionalValue(condition) {
            let configFormElement = Utils.querySelector(this, '.cf-config-form');

            configFormElement.querySelector(`.if-value-container-${condition.id}`)
                .innerHTML = Utils.render(Templates.IfValueTemplate.data, {
                    vm: {
                        condition: condition,
                        component: Utils.getComponent(this.form, condition.ifRule.otherComponentId)
                    }
                });

            let otherComponentValueElement = configFormElement.querySelector(`.cf-component-condition-othercomponent-value-${condition.id}`);
            otherComponentValueElement.value = condition.ifRule.otherComponentValue || '';
        }

        editConfiguration(data) {
            if (this.isInPreviewMode) return;

            document.getElementsByClassName('border-dropdown-button-main')[0].classList.add('cf-hidden');

            localStorage.setItem(`configuration-${this.form.id}`, data);

            let component = Component.parse(JSON.parse(data));
            
            let nonFormElementGroups = ComponentTemplateGroups.filter(x => x.groupKey != 'Form');
            let isComponentFormElement = !nonFormElementGroups.some(x => x.componentTemplates.some(y => y.name == component.name))
            
            component.hasHideFieldOnPdf = null;
            component.hasHideUnselectedOptionsOnPdf = null;
            component.hasPrintWithBorderOnPdf = true;
            if (isComponentFormElement)
            {
                
                if (component.typeId !== FieldInputTypes.Header && component.typeId !== FieldInputTypes.ReadOnlyText)
                {
                    component.hasHideFieldOnPdf = true;
                }

                if (component.typeId == FieldInputTypes.MultiSelect || component.typeId == FieldInputTypes.SingleSelectOptions)
                {
                    component.hasHideUnselectedOptionsOnPdf = true;
                }
            }

            let otherComponentExclusions = [FieldInputTypes.Header, FieldInputTypes.ReadOnlyText, FieldInputTypes.DrawPad, FieldInputTypes.VehicleCanvas,FieldInputTypes.PersonCanvas, FieldInputTypes.PrePopulated];

            let otherComponents = Utils.getAllComponents(this.form)
                .filter(c => c.id !== component.id && !otherComponentExclusions.includes(c.typeId));

            let configElement = Utils.querySelector(this, '.cf-config');

            if (configElement) {
                configElement
                    .innerHTML = Utils.render(Templates.ConfigurationTemplate.data, {
                        vm: {
                            form: this.form,
                            component: component,
                            otherComponents: otherComponents
                        }
                    });
            }

            let configFormElement = Utils.querySelector(this, '.cf-config-form');

            if (configFormElement) {
                configFormElement.addEventListener('submit', function(event) {
                    event.preventDefault();
                });

                configFormElement.querySelectorAll('.cf-component-condition')
                    .forEach((conditionRowElement, conditionRowIndex) => {
                        let condition = component.conditions[conditionRowIndex];

                        let otherComponentSelectorElement = configFormElement.querySelector(`.cf-component-condition-othercomponent-selector-${condition.id}`);
                        otherComponentSelectorElement.value = condition.ifRule.otherComponentId;

                        this.renderConditionalValue(condition);

                        otherComponentSelectorElement.addEventListener('change', event => {
                            condition.ifRule.otherComponentId = event.target.value;
                            this.renderConditionalValue(condition);
                        });
                    });
            }

            this.showConfig();
            EventRegistrations.registerConfigurationSavedEvent.apply(this);
            EventRegistrations.registerOptionAddedEvent.apply(this);
            EventRegistrations.registerConditionAddedEvent.apply(this);
            EventRegistrations.registerConditionDeletedEvent.apply(this);
            EventRegistrations.registerOptionDeletedEvent.apply(this);
            EventRegistrations.registerComponentOptionKeyDownEvent.apply(this);
        }

        getInMemoryConfiguration() {
            let configForm = Utils.querySelector(this, '.cf-config-form');
            let component = Component.parse(JSON.parse(localStorage.getItem(`configuration-${this.form.id}`)));

            component.title = configForm.componentTitle.value;
            component.required = configForm.componentRequired ? configForm.componentRequired.checked : null;

            let hideFieldOnPdfControl =  configForm.querySelectorAll('.cf-component-hidefieldonpdf-control')[0];
            component.hideFieldOnPdf = hideFieldOnPdfControl ? hideFieldOnPdfControl.checked : null;

            let hideUnselectedOptionsOnPdfControl =  configForm.querySelectorAll('.cf-component-hideunselectedoptionsonpdf-control')[0];
            component.hideUnselectedOptionsOnPdf = hideUnselectedOptionsOnPdfControl ? hideUnselectedOptionsOnPdfControl.checked : null;

            let hidePrintWithBorderOnPdfControl = configForm.querySelectorAll('.cf-component-printWithBorderOnPdf-control')[0];
            component.printWithBorderOnPdf = hidePrintWithBorderOnPdfControl ? hidePrintWithBorderOnPdfControl.checked : null;

            let optionRows = configForm.querySelectorAll('.cf-component-option');
            let optionValues = configForm.querySelectorAll('.cf-component-option-value');

            if (optionRows.length > 0) {
                component.options = [];

                optionRows.forEach((_, optionIndex) => {
                    component.options.push({
                        key: optionValues[optionIndex].id,
                        value: optionValues[optionIndex].value
                    })
                });
            }

            component.conditions = [];
            let conditionnRows = configForm.querySelectorAll('.cf-component-condition');

            conditionnRows.forEach(conditionRow => {
                let condition = new Condition();
                condition.id = conditionRow.getAttribute('data-component-condition-id');

                let otherComponentSelectorElement = configForm.querySelector(`.cf-component-condition-othercomponent-selector-${condition.id}`);
                let otherComponentValueElement = configForm.querySelector(`.cf-component-condition-othercomponent-value-${condition.id}`);

                condition.ifRule.otherComponentId = otherComponentSelectorElement.value;
                condition.ifRule.otherComponentValue = otherComponentValueElement.value;
                condition.thenRule.isHidden = configForm.elements.componentVisibility.value === 'hide';

                component.conditions.push(condition);
            });

            return component;
        }

        saveConfiguration(event) {
            let configForm = Utils.querySelector(this, '.cf-config-form');
            let formElements = [...configForm.elements];
            let missingValues = formElements.map(element => element.validity.valueMissing);

            if (!missingValues.includes(true)) {
                event.preventDefault();

                let componentId = configForm.getAttribute('data-component-id');
                let component = Utils.getComponent(this.form, componentId);

                let clonedComponent = this.getInMemoryConfiguration();
                component.title = clonedComponent.title;
                component.required = clonedComponent.required;
                component.hideFieldOnPdf = clonedComponent.hideFieldOnPdf;
                component.hideUnselectedOptionsOnPdf = clonedComponent.hideUnselectedOptionsOnPdf;
                component.printWithBorderOnPdf = clonedComponent.printWithBorderOnPdf;
                component.options = clonedComponent.options;
                component.conditions = clonedComponent.conditions;

                this.buildForm();                

                var scrollDiv = document.getElementById(componentId);
                window.scrollTo({ top: scrollDiv.offsetTop, behavior: 'smooth'});
                scrollDiv.tabIndex ="-1";
                scrollDiv.focus();
            }
        }

        handleFormInputChange(event) {
            if (!this.isInPreviewMode) {
                return;
            }

            let componentElement = event.target.closest('.cf-component');

            if (componentElement) {
                let component = Utils.getComponent(this.form, componentElement.id);

                if (component) {
                    let selectedValues = [];

                    if (component.typeId === FieldInputTypes.SingleSelectList) {
                        let option = component.options.find(o => o.key === event.target.value);
                        selectedValues.push(option.value);
                    } else if (component.typeId === FieldInputTypes.MultiSelect) {
                        let optionElements = Array.from(Utils.querySelectorAll(this, `[data-group-id="${component.id}"]`));
                        
                        let targetElement = optionElements.find(o => o.value === event.target.value)
                        let targetValue = targetElement.getAttribute('data-value');
                        let isTargetNotApplicable = Utils.notApplicableLookup.some(x => x == targetValue.trim().toLowerCase());
                        let isTargetChecked = targetElement.checked;

                        if (isTargetNotApplicable && isTargetChecked)
                        {
                            component.options.filter(x => x.key != event.target.value).forEach(option => {
                                let optionElement = optionElements.find(o => o.value === option.key);

                                option.disabled = true;
                                option.checked = false;
                                optionElement.disabled = true;
                                optionElement.checked = false;

                            })
                            selectedValues.push(targetValue);
                        }
                        else {
                            component.options.forEach(option => {
                                let isOptionNotApplicable = Utils.notApplicableLookup.some(x => x == option.value.trim().toLowerCase())
                                let optionElement = optionElements.find(o => o.value === option.key);
                                
                                option.disabled = false;
                                optionElement.disabled = false;

                                if (isOptionNotApplicable && optionElements.some(x => x.checked))
                                {
                                    option.disabled = true
                                    optionElement.disabled = true;
                                }

                                if (optionElement.checked && !isOptionNotApplicable) {
                                    selectedValues.push(optionElement.getAttribute('data-value'));
                                }
                            })
                        }

                    } else if (component.typeId === FieldInputTypes.SingleSelectOptions) {
                        selectedValues.push(event.target.getAttribute('data-value'));
                    } else if (component.typeId === FieldInputTypes.FileUpload) {
                        let file = event.detail.file;
                        let maxAllowedFilesize = Utils.parseBytes(this.options.maxUploadFileSize);

                        if (file.size <= maxAllowedFilesize) {
                            selectedValues.push(file.content);
                        } else if (Utils.isFunction(this.options.fileUploadErrorCallback)) {
                            this.options.fileUploadErrorCallback({
                                file: file.name,
                                component: component,
                                errorMessage: `Please select a file less than ${Utils.prettifyBytes(maxAllowedFilesize)}.`
                            });
                        }
                    } else if (event.target.value !== '') {
                        selectedValues.push(event.target.value);
                    }

                    component.currentValues = selectedValues;

                    // re-render with file content or checkbox group
                    if (component.typeId === FieldInputTypes.FileUpload) {
                        document.getElementById(component.id)
                            .innerHTML = component.render();
                    }

                    Utils.evaluateConditions(this.form);
                }
            }
        }
        renderNextSection() {
            if (this.currentSection < this.form.sections.length - 1) {
                let nextSection = this.currentSection + 1;
                this.buildSection(nextSection);
                let sectionRendered = Utils.getElementById(this.form.sections[this.currentSection].id);

                while(sectionRendered.classList.contains('cf-hidden') && nextSection < (this.form.sections.length-1) ) 
                {
                    nextSection++;
                    this.buildSection(nextSection)
                    sectionRendered = Utils.getElementById(this.form.sections[nextSection].id)
                }
            }
        }

        renderPreviousSection() {
            if (this.currentSection > 0) {
                let previousSection = this.currentSection - 1;
                this.buildSection(previousSection);
                let sectionRendered = Utils.getElementById(this.form.sections[this.currentSection].id);

                while(sectionRendered.classList.contains('cf-hidden') && previousSection>0)
                {
                    previousSection--;
                    this.buildSection(previousSection)
                    sectionRendered = Utils.getElementById(this.form.sections[previousSection].id);
                }

            }
        }

        IsFirstSection()
        {
            return this.currentSection===0;
        }

        IsLastSection() 
        {
            return this.currentSection===this.form.sections.length-1;
        }

        printAllElementsWithBorder(event) {
            event.preventDefault();

            if (event.currentTarget.id == 'print-all-elements-border-pdf' && event.currentTarget.classList.contains('all-elements-printed-with-border')) {
                this.printAllElementsWithBorderInPdf(false);           
                this.form.hasBorderInAllElementsPdf = false;
                document.getElementById('print-all-elements-border-pdf').classList.remove('all-elements-printed-with-border');
                this.buildForm();
                return;
            }

            this.printAllElementsWithBorderInPdf();
            this.form.hasBorderInAllElementsPdf = true;
            event.currentTarget.classList.add('all-elements-printed-with-border');
            this.buildForm();
        }

        printAllSectionsWithBorder(event) {
            event.preventDefault();

            if (event.currentTarget.id == 'print-all-sections-border-pdf' && event.currentTarget.classList.contains('all-sections-printed-with-border')) {
                this.printAllRowsWithBorderInPdf(false);           
                this.form.hasBorderInAllSectionsPdf = false;
                document.getElementById('print-all-sections-border-pdf').classList.remove('all-sections-printed-with-border');
                this.buildForm();
                return;
            }
            
            this.printAllRowsWithBorderInPdf();
            this.form.hasBorderInAllSectionsPdf = true;
            event.currentTarget.classList.add('all-sections-printed-with-border');
            this.buildForm();
        }

        showBorderDropdownOptions(event) {
            event.preventDefault();

            let dropdownBorderItemsClassList = document.getElementsByClassName('dropdown-border-items')[0].classList;

            dropdownBorderItemsClassList.contains('inactive') ? dropdownBorderItemsClassList.remove('inactive') : dropdownBorderItemsClassList.add('inactive');

            let topLevelBorderButtonClassList = document.getElementById('border-dropdown-button').classList;

            topLevelBorderButtonClassList.contains('background-blue') ? topLevelBorderButtonClassList.remove('background-blue') : topLevelBorderButtonClassList.add('background-blue');
        }

        hideBorderDropdownOptions(event) {
            if (event.target.id == 'border-dropdown-button' || (event.target.id == '' && event.target.parentElement.parentElement.id == 'border-dropdown-button')) {
                return;
            } 

            let dropdownBorderItemsClassList = document.getElementsByClassName('dropdown-border-items')[0].classList;
            dropdownBorderItemsClassList.add('inactive');

            document.getElementById('border-dropdown-button').classList.remove('background-blue');
        }

        handleSectionControlEvent(event) {
            event.preventDefault();
            let sectionId = event.target.closest('.cf-section')
                .id;

            let section = Utils.getSection(this.form, sectionId);
            if (event.target.classList.contains('cf-clone-section')) {                                
                this.form.sections.splice(this.form.sections.indexOf(section)+1,0,Utils.cloneSection(section,true)); 
                this.buildForm();
            } else if (event.target.classList.contains('cf-clone-section-with-conditions')) {                
                this.form.sections.splice(this.form.sections.indexOf(section)+1,0,Utils.cloneSection(section,false));
                this.buildForm();
            }
            else if (event.target.classList.contains('cf-delete-section')) {
                let formElement = Utils.querySelector(this, '.cf-form');
    
                formElement.dispatchEvent(new Event('cfConfirmDeleteRowEvent', {
                    bubbles: true
                }));

                this.currentSection = this.form.sections.findIndex(x=>x.id == sectionId);
            } else if (event.target.classList.contains('cf-section-printed-with-border')) {                        
                section.hasBorderInSectionPdf = false;
                this.buildForm();
            } else if (event.target.classList.contains('cf-section-control')) {                
                section.hasBorderInSectionPdf = true;
                this.buildForm();
            } 
        }

        handleShowConfirmDeleteModal() {
            this.form.sections.splice((this.currentSection), 1);
            this.buildForm();
        }

        handleComponentControlEvent(event) {
            event.preventDefault();

            let sectionId = event.target.closest('.cf-section')
                .id;
            let componentId = event.target.getAttribute('data-component-id');

            let section = Utils.getSection(this.form, sectionId);
            let component = Utils.getComponent(this.form, componentId);

            if (event.target.classList.contains('cf-edit-component')) {
                this.editConfiguration(JSON.stringify(component));
            } else if (event.target.classList.contains('cf-clone-component')) {
                if (section.components.length < this.defaultOptions.rowLimit)
                {
                    section.components.push(Utils.cloneComponent(component));
                    this.buildForm();
                }
            } else if (event.target.classList.contains('cf-delete-component')) {
                section.components = section.components.filter(component => component.id !== componentId);

                if (section.components.length === 0) {
                    this.form.sections = this.form.sections.filter(section => section.id !== sectionId);
                }

                Utils.getAllComponents(this.form)
                    .forEach(cmp => cmp.conditions = cmp.conditions.filter(cnd => cnd.ifRule.otherComponentId !== componentId));

                this.buildForm();
            }
        }

        renderComponentGroupTemplate() {
            let self = this;

            let templatesContainerElement = Utils.querySelector(this, '.cf-component-templates');

            if (templatesContainerElement) {
                templatesContainerElement.innerHTML = Utils.render(Templates.ComponentTemplatesElementsTemplate.data, {
                    vm: {
                        group: this.componentTemplateGroups[0]
                    }
                })
            }

            let templatesElement = Utils.querySelector(self, '.cf-component-templates-elements');

            if (templatesElement) {
                let templateSortable = Sortable.create(templatesElement, {
                    group: {
                        put: false,
                        pull: 'clone',
                    },
                    sort: false,
                    swapThreshold: 0.1,
                    onAdd(event) {
                        event.item.remove();
                        self.reorder();
                    }
                });

                self.sortables.push(templateSortable);
            }

            EventRegistrations.registerComponentTemplatesGroupSelectedEvent.apply(this);
        }

        selectNextGroupTemplates(event) {
            Utils.rotate(this.componentTemplateGroups, false);
            this.renderComponentGroupTemplate();
        }

        selectPreviousGroupTemplates(event) {
            Utils.rotate(this.componentTemplateGroups, true);
            this.renderComponentGroupTemplate();
        }

        optionValueKeyDown(event){
            if(event.key === ','){
                event.preventDefault();
            }
        }

        printAllElementsWithBorderInPdf(doPrint = true) {
            if (doPrint) {
                this.form.sections.forEach(section => {
                    section.components.forEach(component => {
                        component.printWithBorderOnPdf = true;
                    });
                });

                return;
            }

            this.form.sections.forEach(section => {
                section.components.forEach(component => {
                    component.printWithBorderOnPdf = false;
                });
            });
        }

        printAllRowsWithBorderInPdf(doPrint = true) {
            if (doPrint) {
                this.form.sections.forEach(section => {
                    section.hasBorderInSectionPdf = true;
                });
                
                return;
            }

            this.form.sections.forEach(section => {
                section.hasBorderInSectionPdf = false;
            });
        }
    }

    globalThis.FormBuilderUtils = Utils;
    globalThis.FormBuilderFieldInputTypes = FieldInputTypes;

    
})();

