export default {
    MainTemplate: {
        data: `
        <div id="{{vm.id}}" class="cf-form-builder">
            <div class="cf-editor">
                <form id="{{vm.form.id}}" class="cf-form">
                </form>
                <div class="cf-config cf-hidden"></div>
            </div>
            <div class="cf-component-templates">
            </div>
        </div>
    `,
        mdata: `
        <ion-list id="{{vm.id}}" class="cf-form-builder">
            <ion-item-group class="cf-component-templates" no-padding lines="none">
            </ion-item-group>
            <ion-item-group class="cf-editor" no-padding lines="none">
                <form id="{{vm.form.id}}" class="cf-form">
                </form>
            </ion-item-group>
        </ion-list>
    `
    },
    ComponentTemplatesElementsTemplate: {
        data: `
        <div class="cf-component-templates-group">
            <div class="cf-component-templates-header">
                <button type="button" class="cf-component-template-group-previous">&lt</button>
                <label>{{vm.group.groupName}}</label>
                <button type="button" class="cf-component-template-group-next">&gt</button>
            </div>
            <div class="cf-component-templates-elements">
                {{#vm.group.componentTemplates}}
                <button type="button" class="cf-component-template cf-button" data-cf-component-name="{{name}}">
                <span class="material-icons cf-icon {{headerclass}}">{{icon}}</span>
                {{title}}
                </button>
                {{/vm.group.componentTemplates}}
            </div>
        </div>
        `
    },
    FormTemplate: {
        data: `
        {{#vm.form.header.components}}
        <div class="cf-form-header">
            <div id="{{id}}" class="cf-component">
                {{{render}}}
            </div>
        </div>
        {{/vm.form.header.components}}
        {{#vm.form.sections}}        
            <div id="{{id}}" class="cf-section">             
                {{#components}}
                <div id="{{id}}" class="cf-component {{validateField}}">
                    {{{render}}}
                    <div class="cf-component-controls">
                        <button type="button" class="cf-component-control cf-edit-component" data-component-id="{{id}}">Edit</button>
                        <button type="button" class="cf-component-control cf-clone-component" data-component-id="{{id}}">Clone</button>
                        <button type="button" class="cf-component-control cf-delete-component" data-component-id="{{id}}">Delete</button>
                    </div>                                                                                                                       
                </div>                                                                 
                {{/components}} 
                <div class="cf-section-controls dropdown">
                <div class="dropbtn">&#8230;</div>
                    <div class="dropdown-content">
                        <a class="cf-section-control cf-clone-section" href="#">Clone entire row</a>
                        <a class="cf-section-control cf-clone-section-with-conditions" href="#">Clone entire row excl. conditions</a>                                        
                    </div>
                </div>                             
            </div>        
        {{/vm.form.sections}}
    `,
        mdata: `
        {{#vm.form.header.components}}
        <ion-card class="cf-form-header mobile">
            <ion-card-content id="{{id}}" class="cf-component">
                {{{render}}}
            </ion-card-content>
        </ion-card>
        {{/vm.form.header.components}}
        {{#vm.form.sections}}
        <ion-card id="{{id}}" class="cf-section mobile">
            {{#components}}
            <ion-card-content id="{{id}}" class="cf-component">
                {{{render}}}
            </ion-card-content>
            {{/components}}
        </ion-card>
        {{/vm.form.sections}}
    `
    },
    FormTemplateLazyLoading: {
        data: `
        {{#vm.form.header.components}}
        <div class="cf-form-header">
            <div id="{{id}}" class="cf-component">
                {{{render}}}
            </div>
        </div>
        {{/vm.form.header.components}}
        {{#vm.form.sections}}
        <div id="{{id}}" class="cf-section">
            {{#components}}
            <div id="{{id}}" class="cf-component {{validateField}}">
                {{{render}}}
                <div class="cf-component-controls">
                    <button type="button" class="cf-component-control cf-edit-component" data-component-id="{{id}}">Edit</button>
                    <button type="button" class="cf-component-control cf-clone-component" data-component-id="{{id}}">Clone</button>
                    <button type="button" class="cf-component-control cf-delete-component" data-component-id="{{id}}">Delete</button>
                </div>                
            </div>           
            {{/components}}                          
        </div>
        {{/vm.form.sections}}
    `,
        mdata: `
        <ion-progress-bar class="cf-progress-bar" value="0"></ion-progress-bar>
        {{#vm.form.header.components}}
        <ion-card class="cf-form-header mobile">
            <ion-card-content id="{{id}}" class="cf-component">
                {{{render}}}
            </ion-card-content>
        </ion-card>
        {{/vm.form.header.components}}
        <ion-item-group class="cf-sections mobile">
        </ion-item-group>
    `
    },
    SectionLazyLoading: {
   
        mdata: `
        {{#vm.section}}
        
        <ion-card id="{{id}}" class="cf-section mobile cf-section-preview shadow ion-no-margin sc-ion-card-ios-h sc-ion-card-ios-s ios hydrated">
            {{#components}}
            <ion-card-content id="{{id}}" class="cf-component">
                {{{render}}}
            </ion-card-content>
            {{/components}}
        </ion-card>
        {{/vm.section}}
    `
    },
    IfValueTemplate: {
        data: `
        {{^vm.component.hasOptions}}
        <input type="text" class="cf-component-condition-othercomponent-value cf-component-condition-othercomponent-value-{{vm.condition.id}} cf-input" value="{{vm.condition.ifRule.otherComponentValue}}">
        {{/vm.component.hasOptions}}
        {{#vm.component.hasOptions}}
        <select class="cf-component-condition-othercomponent-value cf-component-condition-othercomponent-value-{{vm.condition.id}} cf-input" value="{{vm.condition.ifRule.otherComponentValue}}">
        <option value="{{value}}">
        {{#vm.component.options}}
        <option value="{{value}}">{{value}}</option>
        {{/vm.component.options}}
        </datalist>
        {{/vm.component.hasOptions}}
        `
    },
    ConfigurationTemplate: {
        data: `
        <form class="cf-config-form" data-component-id="{{vm.component.id}}">
            <div class="cf-config-controls">
                <button type="button" type="submit" class="cf-config-save cf-button">Save</button>
                {{#vm.component.hasOptions}}
                <button type="button" class="cf-config-add-option cf-button">Add Option</button>
                {{/vm.component.hasOptions}}
                {{#vm.otherComponents.length}}
                <button type="button" class="cf-config-add-condition cf-button">Add Condition</button>
                {{/vm.otherComponents.length}}
            </div>
            <table class="cf-table cf-config-section">
                <tbody>
                    <tr>
                        <td><strong>Name</strong></td>
                        <td>
                            <input type="text" 
                                   name="componentTitle"
                                   value="{{vm.component.title}}" 
                                   class="cf-component-title cf-input" 
                                   maxlength="{{vm.component.characterLimits.title}}" 
                                   required>
                            <label class="cf-input-char-limit">Max {{vm.component.characterLimits.title}} characters</label>
                        </td>
                    </tr>
                    {{#vm.component.hasRequired}}
                    <tr>
                        <td><strong>Is Required?</strong></td>
                        <td>
                            <input name="componentRequired" type="checkbox" class="cf-component-required cf-input" value="{{vm.component.required}}" {{#vm.component.required}}checked{{/vm.component.required}}>
                        </td>
                    </tr>
                    {{/vm.component.hasRequired}}
                </tbody
            </table>
            {{#vm.component.options.length}}
            <table class="cf-table cf-config-section">
                <tbody>
                <tr>
                    <td><strong>Options</strong></td>
                    <td><label class="cf-input-char-limit">Max {{vm.component.characterLimits.options}} character</label></td>                        
                </tr>
                <tr>
                    <td></td>
                    <td><label class="cf-options-comma-warning">Comma is not supported in options value field</label></td>                        
                </tr>
                    {{#vm.component.options}}
                    <tr class="cf-component-option">
                        <td>                           
                        </td>
                        <td>
                            <input type="text" 
                                   id="{{key}}" 
                                   class="cf-component-option-value cf-input" 
                                   value="{{value}}" 
                                   placeholder="Enter unique value" 
                                   maxlength="{{vm.component.characterLimits.options}}"
                                   required>
                        </td>
                        <td>
                            <button type="button" class="cf-config-delete-option cf-button" data-component-option-key="{{key}}">X</button>
                        </td>
                    </tr>
                    {{/vm.component.options}}
                </tbody>
            </table>
            {{/vm.component.options.length}}
            {{#vm.otherComponents.length}}
            {{#vm.component.conditions.length}}
            <table class="cf-table cf-config-section">
                <tbody>
                    <tr>
                        <td colspan="100%">
                            <strong>Conditions</strong>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="100%">
                            <select name="componentVisibility" class="cf-source-visibility cf-input">
                                <option value="show" {{^vm.component.isHiddenByDefault}} selected {{/vm.component.isHiddenByDefault}}>Show</option>
                                <option value="hide" {{#vm.component.isHiddenByDefault}} selected {{/vm.component.isHiddenByDefault}}>Hide</option>
                            </select>&nbspwhen all of the following conditions are met.
                        </td>
                    </tr>
                    {{#vm.component.conditions}}
                    <tr class="cf-component-condition" data-component-condition-id="{{id}}">
                        <td>Value of</td>
                        <td>
                            <select class="cf-component-condition-othercomponent-selector cf-component-condition-othercomponent-selector-{{id}} cf-input" value="{{ifRule.otherComponentId}}">
                                <option value=""></option>
                                {{#vm.otherComponents}}
                                <option value="{{id}}">{{title}}</option>
                                {{/vm.otherComponents}}
                            </select>
                        </td>
                        <td>is</td>
                        <td>
                            <div class="if-value-container-{{id}}">
                            </div>
                        </td>
                        <td>
                            <button type="button" class="cf-config-delete-condition cf-button" data-component-condition-id="{{id}}">X</button>
                        </td>
                    </tr>
                    {{/vm.component.conditions}}
                </tbody>
            </table>
            {{/vm.component.conditions.length}}
            {{/vm.otherComponents.length}}
        </form>
    `
    },
    HeaderTemplate: {
        id: 'header',
        data: `
        <label><h1>{{vm.title}}</h1></label>
    `,
        mdata: `
        <ion-item lines="none" class="cf-component-header ion-no-padding ion-no-margin">
            <ion-label class="ion-no-padding ion-no-margin ion-text-wrap">{{vm.title}}</ion-label>
        </ion-item>
    `
    },
    LabelTemplate: {
        id: 'label',
        data: `
        <label></label>
        <span>{{vm.title}}</span>
    `,
        mdata: `
        <ion-item lines="none" class="cf-component-label ion-no-padding ion-no-margin">
          <ion-label class="pre ion-no-padding ion-no-margin ion-text-wrap">{{vm.title}}</ion-label>
        </ion-item>
    `
    },
    InputTemplate: {
        id: 'input',
        data: `
        <label {{#vm.required}}class="cf-required"{{/vm.required}}>{{vm.title}}</label>
        <span>
            {{#isFileUpload}}
                <div class="cf-upload-file">
                    <div class="cf-upload-file-controls">
                        <input  type="file"
                                id="file-{{vm.id}}"
                                type='{{vm.type}}'
                                class="cf-upload-file-input"
                                oninput="FormBuilderUtils.handleChangeEvent(this)"/>
                        <input  type="button"
                                value="Choose file"
                                onclick="FormBuilderUtils.openFileDialog('file-{{vm.id}}')" />
                        <input  type="text"
                                readonly
                                name='{{vm.id}}'
                                class="cf-component-element cf-input cf-upload-file-name"
                                placeholder="No file selected"
                                {{#vm.currentValues.length}}value='A file is selected'{{/vm.currentValues.length}}>
                    </div>
                    {{#vm.currentValues.length}}
                        {{#vm.currentValues.0.length}}
                            <img alt=""
                                class="cf-image cf-upload-file-content"
                                src="{{{vm.currentValues.0}}}">
                        {{/vm.currentValues.0.length}}
                        {{^vm.currentValues.0.length}}
                            <span class="cf-no-file-uploaded">No files uploaded</span>
                        {{/vm.currentValues.0.length}}
                    {{/vm.currentValues.length}}
                </div>
            {{/isFileUpload}}
            {{^isFileUpload}}
                <input oninput="FormBuilderUtils.handleChangeEvent(this)" class="cf-component-element cf-input" type='{{vm.type}}' name='{{vm.id}}' {{#vm.currentValues.length}}value="{{vm.currentValues.0}}"{{/vm.currentValues.length}}>
            {{/isFileUpload}}
        </span>
    `,
        mdata: `
        <ion-item-group>
            <ion-label {{#vm.required}}class="cf-required"{{/vm.required}}>{{vm.title}}</ion-label>
            {{#isDate}}
            <ion-datetime onblur="FormBuilderUtils.handleChangeEvent(this)"
                            class="cf-component-element cf-input mobile"
                            type='{{vm.type}}'
                            name='{{vm.id}}'
                            {{#vm.currentValues.length}}value="{{vm.currentValues.0}}"{{/vm.currentValues.length}}></ion-datetime>
            {{/isDate}}
            {{#isTime}}
            <ion-datetime   onblur="FormBuilderUtils.handleChangeEvent(this)"
                            display-format="HH:mm"
                            class="cf-component-element cf-input mobile"
                            type='{{vm.type}}'
                            name='{{vm.id}}'
                            {{#vm.currentValues.length}}value="{{vm.currentValues.0}}"{{/vm.currentValues.length}}></ion-datetime>
            {{/isTime}}
            {{#isFileUpload}}
            <ion-item-group class="cf-upload-file">
                <ion-item class="cf-upload-file-controls">
                    <input  type="file"
                            id="file-{{vm.id}}"
                            type='{{vm.type}}'
                            class="cf-upload-file-input"
                            oninput="FormBuilderUtils.handleChangeEvent(this)">
                    <ion-button value="Choose file"
                                no-padding lines="none"
                                class="cf-upload-file-button"
                                onclick="FormBuilderUtils.openFileDialog('file-{{vm.id}}')">Choose File</ion-button>
                    <ion-input  type="text"
                                readonly
                                class="cf-component-element cf-input cf-upload-file-name mobile"
                                name='{{vm.id}}'
                                placeholder="No file selected"
                                {{#vm.currentValues.length}}value='A file is selected'{{/vm.currentValues.length}}></ion-input>
                </ion-item>
                {{#vm.currentValues.length}}
                    {{#vm.currentValues.0.length}}
                        <img alt=""
                            class="cf-image cf-upload-file-content"
                            src="{{{vm.currentValues.0}}}">
                    {{/vm.currentValues.0.length}}
                    {{^vm.currentValues.0.length}}
                        <span class="cf-no-file-uploaded">No files uploaded</span>
                    {{/vm.currentValues.0.length}}
                {{/vm.currentValues.length}}
            </ion-item-group>
            {{/isFileUpload}}
            {{#isGeneral}}
            <ion-input oninput="FormBuilderUtils.handleChangeEvent(this)"
                        class="cf-component-element cf-input mobile"
                        type='{{vm.type}}'
                        name='{{vm.id}}'
                        {{#vm.currentValues.length}}value="{{vm.currentValues.0}}"{{/vm.currentValues.length}}></ion-input>
            {{/isGeneral}}
        </ion-item-group>
    `
    },
    TextareaTemplate: {
        id: 'textarea',
        data: `
        <label {{#vm.required}}class="cf-required"{{/vm.required}}>{{vm.title}}</label>
        <span>
            <textarea oninput="FormBuilderUtils.handleChangeEvent(this)" 
                      class="cf-component-element cf-input" name='{{vm.id}}'>{{vm.currentValues.0}}</textarea>
        </span>
    `,
        mdata: `
        <ion-item-group>
            <ion-label {{#vm.required}}class="cf-required"{{/vm.required}}>{{vm.title}}</ion-label>
            <ion-textarea auto-grow="true" oninput="FormBuilderUtils.handleChangeEvent(this)" class="cf-component-element cf-input mobile" name='{{vm.id}}' value="{{vm.currentValues.0}}"></ion-textarea>
        </ion-item-group>
    `
    },
    CheckboxGroupTemplate: {
        id: 'checkboxgroup',
        data: `
        <label {{#vm.required}}class="cf-required"{{/vm.required}}>{{vm.title}}</label>
        <span class="cf-component-element">
            {{#vm.options}}
            <input name='{{vm.id}}' type='checkbox' value='{{key}}' onclick="FormBuilderUtils.handleChangeEvent(this)" class="cf-input" data-group-id="{{vm.id}}" data-value="{{value}}" {{#checked}}checked="checked"{{/checked}}>
            <span>{{value}}</span>
            {{/vm.options}}
        </span>
    `,
        mdata: `
        <ion-item-group>
            <ion-label {{#vm.required}}class="cf-required"{{/vm.required}}>{{vm.title}}</ion-label>
            <ion-list>
                {{#vm.options}}
                <ion-item no-padding lines="none" class="cf-component-element cf-component-checkbox">
                    <ion-checkbox mode="md" slot="start"  name='{{vm.id}}' value='{{key}}' class="cf-input mobile" data-group-id="{{vm.id}}" data-value="{{value}}" {{#checked}}checked=true{{/checked}}></ion-checkbox>
                    <ion-label>{{value}}</ion-label>
                </ion-item>
                {{/vm.options}}
            </ion-list>
        </ion-item-group>
    `
    },
    RadioGroupTemplate: {
        id: 'radiogroup',
        data: `
        <label {{#vm.required}}class="cf-required"{{/vm.required}}>{{vm.title}}</label>
        <span class="cf-component-element">
            {{#vm.options}}
            <input type='radio' name='{{vm.id}}' value='{{key}}' onclick="FormBuilderUtils.handleChangeEvent(this)" class="cf-input" data-value="{{value}}" {{#checked}}checked="checked"{{/checked}}>
            <span>{{value}}</span>
            {{/vm.options}}
        </span>
    `,
        mdata: `
        <ion-item-group>
            <ion-label {{#vm.required}}class="cf-required"{{/vm.required}}>{{vm.title}}</ion-label>
            <ion-list class="cf-component-element">
                <ion-radio-group>
                    {{#vm.options}}
                    <ion-item lines="none" class="cf-component-radio-group">
                        <ion-label>{{value}}</ion-label>
                        <ion-radio mode="md" name='{{vm.id}}' value='{{key}}' class="cf-input mobile" data-value="{{value}}" {{#checked}}checked="checked"{{/checked}}></ion-radio>
                    </ion-item>
                    {{/vm.options}}
                <ion-radio-group>
            </ion-list>
        </ion-item-group>
    `
    },
    DropdownListTemplate: {
        id: 'dropdownlist',
        data: `
        <label {{#vm.required}}class="cf-required"{{/vm.required}}>{{vm.title}}</label>
        <span>
            <select class="cf-component-element cf-input" name='{{vm.id}}' onchange="FormBuilderUtils.handleChangeEvent(this)">
                <option></option>
                {{#vm.options}}
                <option value='{{key}}' {{#selected}}selected="selected"{{/selected}}>{{value}}</option>
                {{/vm.options}}
            </select>
        </span>
    `,
        mdata: `
        <ion-item-group>
            <ion-item no-padding lines="none">
                <ion-label {{#vm.required}}class="cf-required"{{/vm.required}}>{{vm.title}}</ion-label>
                <ion-select class="cf-component-element cf-input mobile" name='{{vm.id}}'>
                    {{#vm.options}}
                    <ion-select-option value='{{key}}' {{#selected}}selected="selected"{{/selected}}>{{value}}</ion-select-option>
                    {{/vm.options}}
                </ion-select>
            </ion-item>
        </ion-item-group>
    `
    },
    SignaturePadTemplate: {
        id: 'signaturepad',
        data: `
        <label {{#vm.required}}class="cf-required"{{/vm.required}}>{{vm.title}}</label>
        <span>
            <div class="cf-component-element cf-image cf-signature-pad"
                        {{#vm.currentValues.length}}style="background-repeat:no-repeat; background-image: url({{{vm.currentValues.0}}})"{{/vm.currentValues.length}}>
            </div>
        </span>
    `,
        mdata: `
        <ion-item-group>
            <ion-label {{#vm.required}}class="cf-required"{{/vm.required}}>{{vm.title}}</ion-label>
            <ion-item no-padding no-margin lines="none" class="cf-component-canvas">
                <span slot="end" class="cf-canvas-clear">
                    <ion-icon mode="md" name="close" color="medium" slot="end"></ion-icon> Clear
                </span>
            </ion-item>
            <ion-item no-padding no-margin lines="none" class="cf-component-canvas cf-component-element cf-image cf-signature-pad cf-rounded-border">
                <div class="cf-canvas-container">
                    <canvas id="canvas-{{vm.id}}" name='{{vm.id}}' class="cf-canvas"></canvas>
                </div>
            </ion-item>
        </ion-item-group>
    `
    },
    VehicleCanvasTemplate: {
        id: 'vehiclecanvas',
        data: `
        <label {{#vm.required}}class="cf-required"{{/vm.required}}>{{vm.title}}</label>
        <span>
            <div class="cf-component-element cf-image cf-vehicle-canvas"
                 {{#vm.currentValues.length}}style="background-repeat:no-repeat; background-image: url({{{vm.currentValues.0}}})"{{/vm.currentValues.length}}>
            </div>
        </span>
    `,
        mdata: `
        <ion-item-group>
            <ion-label {{#vm.required}}class="cf-required"{{/vm.required}}>{{vm.title}}</ion-label>
            <ion-item no-padding no-margin lines="none" class="cf-component-canvas">
                <span slot="end" class="cf-canvas-clear">
                    <ion-icon mode="md" name="close" color="medium" slot="end"></ion-icon> Clear
                </span>
            </ion-item>
            <ion-item no-padding no-margin lines="none" class="cf-component-canvas cf-component-element cf-image cf-vehicle-canvas cf-rounded-border">
                <div class="cf-canvas-container">
                    <canvas id="canvas-{{vm.id}}" name='{{vm.id}}' class="cf-canvas"></canvas>
                </div>
            </ion-item>
        </ion-item-group>
    `
    },
    SystemField: {
        id: 'systemfield',
        data: `
        <label {{#vm.required}} class="cf-required"{{/vm.required}}>{{vm.title}}</label>
        <span>
            <span class="cf-component-element">{{vm.content}}</span>
        </span>
    `,
        mdata: `
        <ion-item-group>
            <ion-label {{#vm.required}} class="cf-required"{{/vm.required}}>{{vm.title}}</ion-label>
            <ion-label class="cf-component-element ion-text-wrap">{{vm.content}}</ion-label>
        </ion-item-group>
    `
    }
};