import Mustache from 'mustache';
const bytes = require('bytes');
const uuidv4 = require('uuid/v4');

let openFileDialogCallback;

import {
    Component
} from './components.js';

import {
    FormSection
} from './form.js';

export const notApplicableLookup = [null,undefined,"not applicable","n.a","n/a","n.a.",""];

export function parseBytes(value) {
    return bytes.parse(value);
}

export function prettifyBytes(value) {
    return bytes(value);
}

export function uniqueId() {
    return uuidv4();
}

export function rotate(arr, reverse) {
    if (reverse) arr.unshift(arr.pop());
    else arr.push(arr.shift());
    return arr;
}

export function render(template, view) {
    return Mustache.render(template, view);
}

export function querySelector(formBuilder, selector) {
    return document.getElementById(formBuilder.id)
        .querySelector(selector);
}

export function getElementById(id) {
    return document.getElementById(id)
}

export function getElementsByTagName(tagName) {
    return document.getElementsByTagName(tagName)
}

export function querySelectorAll(formBuilder, selector) {
    return document.getElementById(formBuilder.id)
        .querySelectorAll(selector);
}

export function getSection(form, id) {
    return form.sections.find(section => section.id === id);
}

export function getComponent(form, id) {
    return getAllComponents(form)
        .find(component => component.id === id);
}

export function getAllComponents(form) {
    let allComponents = [];
    form.sections.map(section => allComponents.push(...section.components));
    allComponents.push(...form.header.components);

    return allComponents;
}

export function getCondition(component, id) {
    return component.conditions.find(condition => condition.id === id);
}

export function cloneComponent(componentData) {
    let clonedComponent = Component.parse(componentData);
    clonedComponent.id = uniqueId();   
    
    if(clonedComponent.hasConditions){
       clonedComponent.conditions.forEach(condition => condition.id = uniqueId());        
    }

    if(clonedComponent.hasOptions){    
        clonedComponent.options.forEach(option => option.key = uniqueId());    
    }    
    return clonedComponent;
}

export function cloneSection(sectionData, cloneWithCondtions) {   
    
    let clonedSection = FormSection.parse(sectionData);
    clonedSection.id = uniqueId();   
    clonedSection.components.forEach(component => component.id = uniqueId());
   
    clonedSection.components.forEach(component =>
        {
            if(component.hasOptions)
           {
                component.options.forEach(option=> option.key= uniqueId())
           }
       }); 
       
       if(cloneWithCondtions)
       {           
        clonedSection.components.forEach(component =>
            {
                if(component.hasConditions)
                {
                    component.conditions.forEach(condition=> condition.id= uniqueId())        
                }
            });
       }else
       {        
         clonedSection.components.forEach(component => component.conditions=[]);
       }

    return clonedSection;
}

export function handleChangeEvent(sender, data) {
    let event = new CustomEvent('cfEvent', {
        bubbles: true,
        detail: data
    });

    sender.dispatchEvent(event);
}

export function evaluateConditions(form) {
    getAllComponents(form)
        .forEach(component => {
            let componentElement = document.getElementById(component.id);

             if(!componentElement)
             {
                 return;
             }   
             
            let conditionResults = component.conditions.map(condition => {
                let otherComponent = getComponent(form, condition.ifRule.otherComponentId);

                if (otherComponent) {
                    return otherComponent.currentValues.includes(condition.ifRule.otherComponentValue);
                }

                return false;
            });

            if (component.conditions.length > 0) {
                let visibleByDefault = component.conditions[0].thenRule.isHidden;

                if (visibleByDefault) {
                    componentElement.classList.remove('cf-hidden');
                } else {
                    componentElement.classList.add('cf-hidden');
                }

                if (conditionResults.every(p => p)) {
                    if (visibleByDefault) {
                        componentElement.classList.add('cf-hidden');
                    } else {
                        componentElement.classList.remove('cf-hidden');
                    }
                }
            }
        });

    form.sections.forEach(section => {
        let sectionElement = document.getElementById(section.id);

        if(!sectionElement)
        {
            return;
        }

        if (section.components.every(c => document.getElementById(c.id)
                .classList.contains('cf-hidden'))) {
            sectionElement.classList.add('cf-hidden')
        } else {
            sectionElement.classList.remove('cf-hidden')
        }
    });
}

export function isFunction(functionToCheck) {
    return functionToCheck && {}.toString.call(functionToCheck) === '[object Function]';
}

export function replaceOpenFileDialogBehavior(callback) {
    this.openFileDialogCallback = callback;
}

export function openFileDialog(fileDialogId) {
    if (this.openFileDialogCallback)
    {
        this.openFileDialogCallback(fileDialogId);
    }
    else {
        let target = document.getElementById(fileDialogId);

        target.oninput = function(event) {
            let reader = new FileReader();
            let file = event.target.files[0];
    
            reader.onload = event => {
                FormBuilderUtils.handleChangeEvent(target, {
                    file: {
                        name: file.name,
                        size: file.size,
                        type: file.type,
                        content: event.target.result
                    }
                });
            }
    
            reader.readAsDataURL(file);
        }
    
        target.click();
    }
}

export function checkValueIfNotApplicable(arrayOfValues)
{
    return notApplicableLookup.some(item => {

        return arrayOfValues.some(val => {
            
            let fixedValue = val ? val.trim().toLowerCase() : val;
            return fixedValue === item;
        })
    });
}