import {
    Component,
    JobComponentTemplates
} from './components.js';

import * as Utils from './utils.js'

export class Form {
    constructor() {
        this.id = Utils.uniqueId();
        this.header = new FormSection();
        this.hasBorderInAllElementsPdf = false;
        this.hasBorderInAllSectionsPdf = false;
        this.sections = [];

        // Ticket number is expected to always be a part of the form. Users do 
        let ticketNumberComponent = JobComponentTemplates.find(t => t.name === 'ticketnumber');
        ticketNumberComponent.copyTo(this.header);
    }

    static parse(formData = {}) {
        let form = new Form();
        form.id = formData.id;
        form.header = FormSection.parse(formData.header);
        form.hasBorderInAllElementsPdf = formData.hasBorderInAllElementsPdf;
        form.hasBorderInAllSectionsPdf = formData.hasBorderInAllSectionsPdf;
        (formData.sections || [])
        .forEach(sectionData => {
            let section = FormSection.parse(sectionData);
            //hide submission gps section in mobile when its the only component in the section 
            let isSectionContainsSubmissionGps = sectionData.components.every(x => x.content == '{FORM_SUBMISSIONGPS}');
            section.hideSectionInMobile = isSectionContainsSubmissionGps;
            form.sections.push(section)
        });
        return form;
    }
}

export class FormSection {
    constructor() {
        this.id = Utils.uniqueId();
        this.components = [];

        let printAllSectionControl = globalThis.document.getElementById('print-all-sections-border-pdf')
        this.hasBorderInSectionPdf = printAllSectionControl ? printAllSectionControl.classList.contains('all-sections-printed-with-border') : false;
    }

    static parse(sectionData = {}) {
        let section = new FormSection();
        section.id = sectionData.id;
        section.hasBorderInSectionPdf = sectionData.hasBorderInSectionPdf;
        (sectionData.components || [])
        .forEach(componentData => section.components.push(Component.parse(componentData)));
        return section;
    }
}